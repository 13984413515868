import { cloneDeep, each, get, isEmpty, mergeWith, omitBy, pick, startsWith, unset } from 'lodash';

export default class Configuration {
    constructor() {
        this.configuration = {};
        this.injectedConfig = {};
        this.defaultConfig = {};
    }

    getConfig(mwcIdPath, defaultConfig, injectedConfig) {
        this.registerComponent(mwcIdPath, defaultConfig, injectedConfig);
        const instanceConfig = this.getConfigFromId(mwcIdPath);

        return instanceConfig;
    }

    setAppConfig(appConfig) {
        mergeWith(this.configuration, appConfig, this.mergeCustomizer);
    }

    registerComponent(mwcIdPath, defaultConfig, injectedConfig = {}) {
        // Reset injected config every time there's a new register(except it was already been injected)
        // I.e., if component is reinstantiated
        const isInjectedValueEmpty = isEmpty(get(this.injectedConfig, mwcIdPath, {}));

        if (!!injectedConfig && isInjectedValueEmpty) {
            this.injectedConfig[mwcIdPath] = injectedConfig;
        }

        // If something has already set this defaultConfig, make sure we do not overwrite the value.
        this.defaultConfig[mwcIdPath] = mergeWith({}, defaultConfig, this.defaultConfig[mwcIdPath], this.mergeCustomizer);
    }

    getConfigFromId(id) {
        // We think this could stand some improvement to how we cascade down application level settings
        // On the first pass the app-level settings could be treated as parent settings which would
        // simplify the code a little bit
        const mergedConfiguration = cloneDeep(pick(this.configuration, 'labels', 'settings'));
        // Remove any private settings set at the top-level
        mergedConfiguration.settings = omitBy(mergedConfiguration.settings, (value, key) => startsWith(key, '_'));
        const mwcIdPathArray = id ? id.split('.') : [];
        let currentMwcIdPath = '';
        let accumulatedConfiguration;
        let parentGlobalConfig;

        each(mwcIdPathArray, (mwcId, depth) => {
            currentMwcIdPath = isEmpty(currentMwcIdPath) ? mwcId : `${currentMwcIdPath}.${mwcId}`;

            const defaultConfiguration = get(this.defaultConfig, currentMwcIdPath, {});
            const injectedConfiguration = get(this.injectedConfig, currentMwcIdPath, {});

            // Get the global config for this component
            let globalConfiguration = get(
                this.configuration,
                `components.${currentMwcIdPath.split('.').join('.components.')}`,
                {},
            );

            // Merge the config for this component with that from the parent so that setting values from application config cascade down
            if (!parentGlobalConfig) {
                parentGlobalConfig = mergeWith({}, globalConfiguration);
                // Remove any private settings set on the parent
                parentGlobalConfig.settings = omitBy(parentGlobalConfig.settings, (value, key) => startsWith(key, '_'));
            } else {
                globalConfiguration = mergeWith(parentGlobalConfig, globalConfiguration);
            }

            accumulatedConfiguration = get(
                accumulatedConfiguration || defaultConfiguration,
                `components.${mwcId}`,
                {},
            );
            accumulatedConfiguration = mergeWith(
                {},
                defaultConfiguration,
                accumulatedConfiguration,
                this.mergeCustomizer,
            );

            mergeWith(
                mergedConfiguration,
                accumulatedConfiguration,
                injectedConfiguration,
                globalConfiguration,
                this.mergeCustomizer,
            );

            // Remove all private settings and child components, unless this is the last node.
            if (depth < mwcIdPathArray.length - 1) {
                mergedConfiguration.settings = omitBy(mergedConfiguration.settings, (value, key) => startsWith(key, '_'));
                unset(mergedConfiguration, 'components');
            }
        });

        return mergedConfiguration;
    }

    // eslint-disable-next-line consistent-return, class-methods-use-this
    mergeCustomizer(a, b) {
        if (b instanceof Array) {
            return b;
        }
    }
}
