import { remove, each } from 'lodash';
import ConsoleLogProvider from './console-log-provider';

export default class Logger {
    constructor() {
        this.logTypes = {
            FATAL: 1,
            ERROR: 2,
            WARN: 4,
            INFO: 8,
            DEBUG: 16,
            TRACE: 32,
        };

        this.logTypesReverse = {
            1: 'FATAL',
            2: 'ERROR',
            4: 'WARN',
            8: 'INFO',
            16: 'DEBUG',
            32: 'TRACE',
        };

        this.logLevels = {
            NONE: 0,
            ALL: this.logTypes.FATAL || this.logTypes.ERROR || this.logTypes.WARN || this.logTypes.INFO || this.logTypes.DEBUG || this.logTypes.TRACE,
            DEFAULT: this.logTypes.FATAL || this.logTypes.ERROR,
        };
        this.logProviders = [];
        this.logLevel = 32;

        this.addLogProvider(new ConsoleLogProvider(), 'default', 'ALL');
    }

    addLogProvider(logProvider, logProviderId, logLevel) {
        this.removeLogProvider('default');
        const providerObject = {
            provider: logProvider,
            id: logProviderId,
            level: logLevel,
        };
        this.logProviders.push(providerObject);
    }

    removeLogProvider(logProviderId) {
        remove(this.logProviders, logProvider => logProvider.id === logProviderId);
    }

    log(severity, mwcId, message, stackTrace) {
        switch (this.logTypes[severity]) {
            case 1:
                this.fatal(mwcId, message, stackTrace);
                break;
            case 2:
                this.error(mwcId, message, stackTrace);
                break;
            case 4:
                this.warn(mwcId, message, stackTrace);
                break;
            case 8:
                this.info(mwcId, message, stackTrace);
                break;
            case 16:
                this.debug(mwcId, message, stackTrace);
                break;
            case 32:
                this.trace(mwcId, message, stackTrace);
                break;
            default:
                // Throw? or info?
                break;
        }
    }

    error(mwcId, message, stackTrace) {
        this._log('error', mwcId, message, stackTrace);
    }
    fatal(mwcId, message, stackTrace) {
        this._log('fatal', mwcId, message, stackTrace);
        // Do more?
    }
    warn(mwcId, message, stackTrace) {
        this._log('warn', mwcId, message, stackTrace);
    }
    info(mwcId, message, stackTrace) {
        this._log('info', mwcId, message, stackTrace);
    }
    debug(mwcId, message, stackTrace) {
        this._log('debug', mwcId, message, stackTrace);
    }
    trace(mwcId, message, stackTrace) {
        this._log('trace', mwcId, message, stackTrace);
    }

    _log(severity, mwcId, message, stackTrace) {
        const self = this;
        each(this.logProviders, (providerContainer) => {
            const logLevel = typeof providerContainer.level;

            let parsedLogLevel = self.logLevel;

            if (logLevel === 'string') {
                parsedLogLevel = self.logLevels[providerContainer.level];
            }

            if (logLevel === 'number') {
                parsedLogLevel = providerContainer.level;
            }

            if (parsedLogLevel && self.logTypes[severity.toUpperCase()]) {
                providerContainer.provider.log(severity, mwcId, message, stackTrace);
            }
        });
    }

    _getLogLevel(logLevel) {
        let result = this.logLevel;
        if (typeof logLevel === 'string') {
            result = this.logLevels[logLevel] || this.logTypes[logLevel] || this.logLevels.ALL;
        } else if (typeof logLevel === 'number') {
            result = logLevel;
        }

        return result;
    }
}
