/* eslint-disable no-console */
export default class ConsoleLogProvider {
    log(severity, mwcId, message, stackTrace) {
        switch (severity) {
            case 'fatal':
                console.fatal(this.constructor.buildMessage(mwcId, message, stackTrace));
                break;
            case 'error':
                console.error(this.constructor.buildMessage(mwcId, message, stackTrace));
                break;
            case 'warn':
                console.warn(this.constructor.buildMessage(mwcId, message, stackTrace));
                break;
            case 'info':
                console.info(this.constructor.buildMessage(mwcId, message, stackTrace));
                break;
            case 'debug':
                console.debug(this.constructor.buildMessage(mwcId, message, stackTrace));
                break;
            case 'trace':
                console.trace(this.constructor.buildMessage(mwcId, message, stackTrace));
                break;
            default:
                // Throw? or info?
                break;
        }
    }
    static buildMessage(mwcId, message, stackTrace) {
        let stack = '';
        if (stackTrace) {
            stack = ` StackTrace, ${stackTrace}`;
        }
        return `${mwcId} : ${message}${stack}`;
    }
}
