import { forEach, includes } from 'lodash';

export default class DataManager {
    constructor() {
        this.dataSources = {};
    }

    registerDataSource(properties) {
        /*
        properties: {
            dataSourceId,
            source,
            parameters
        } */
        const id = properties.dataSourceId;
        if (!Object.prototype.hasOwnProperty.call(this, id)) {
            this.dataSources[id] = {
                source: properties.source,
                parameters: properties.parameters,
                subscribers: [],
            };
        }
    }

    updateDataSource(properties) {
        /*
        properties: {
            dataSourceId,
            parameters
        } */
        const id = properties.dataSourceId;
        const dataSource = this.dataSources[id];
        dataSource.parameters = properties.parameters;
        // merge(dataSource.parameters, properties.parameters);
        dataSource.source(dataSource.parameters).then((data) => {
            // here, need cache data with the parameters string
            dataSource.data = data;
            this.setSubscriberData(dataSource);
        });
    }

    subscribe(properties) {
        /*
        properties: {
            mwcId,
            element,
            modelName,
            dataSourceId,
            transformationPipeline
        } */
        const dataSource = this.dataSources[properties.dataSourceId];

        const subscriptionExists = includes(dataSource.subscribers, { mwcId: properties.mwcId });
        if (!subscriptionExists) {
            dataSource.subscribers.push({
                component: properties.component,
                element: properties.element,
                modelName: properties.modelName,
                transformationPipeline: properties.transformationPipeline,
            });
        }

        if (!dataSource.data) {
            dataSource.source(dataSource.parameters).then((data) => {
                dataSource.data = data;
                this.setSubscriberData(dataSource);
            });
        } else {
            this.setSubscriberData(dataSource);
        }
    }

    subscribeComponent(mwcId, dataSourcesSettings, element) {
        forEach(dataSourcesSettings, (dataSourceSetting) => {
            if (dataSourceSetting && dataSourceSetting.dataSourceId) {
                const subscribePropertyObject = {
                    mwcId,
                    element,
                    modelName: dataSourceSetting.modelName,
                    dataSourceId: dataSourceSetting.dataSourceId,
                    transformationPipeline: dataSourceSetting.transformations,
                };
                this.subscribe(subscribePropertyObject);
            }
        });
    }

    /* eslint-disable class-methods-use-this */
    setSubscriberData(dataSource) {
        dataSource.subscribers.forEach((subscriber) => {
            const subscriberData = DataManager.transform(subscriber.transformationPipeline || [], dataSource.data);
            subscriber.element[subscriber.modelName] = subscriberData;
        });
    }
    /* eslint-enable class-methods-use-this */

    static transform(transformationPipeline, data, params) {
        transformationPipeline.forEach((transformation) => {
            data = transformation(data, params);
        });
        return data;
    }
}
