import { assign, omit, isUndefined, isNull, isFinite, isDate } from 'lodash';
import baseFormats from './formats.json';

export default class Formatter {
    constructor(languageId, overrideFormats, currencyCode) {
        this.languageId = languageId || 'en-US';
        this.currencyCode = currencyCode || 'USD';
        this.formats = assign({}, baseFormats, overrideFormats);
    }

    format(value, type, opts) {
        // If value is not set, return nullString
        if (isUndefined(value) || isNull(value)) {
            return this.formats.nullString;
        }

        const derivedFormat = this.constructor.getDerivedFormat(this.formats, value, type);

        // Select appropriate opts based on input
        opts = opts || this.formats[type] || this.formats[derivedFormat];
        opts = omit(opts, 'dataType');


        let formattedValue;
        switch (derivedFormat) {
            case 'number':
                // todo: add fallback for old Browsers without polyfill
                formattedValue = Intl.NumberFormat(this.languageId, opts).format(value);
                break;
            case 'date':
                // todo: add fallback for old Browsers without polyfill
                formattedValue = Intl.DateTimeFormat(this.languageId, opts).format(value);
                break;
            case 'abbreviation':
                // todo: add fallback for old Browsers without polyfill
                formattedValue = Intl.NumberFormat(this.languageId, opts).format(value / opts.abbreviate.modeValue) + opts.abbreviate.abbreviations[opts.abbreviate.mode];
                break;
            case 'text':
            default:
                formattedValue = value;
        }

        // todo: implement abbreviations, suffixes, special currencies, currencySymbolOnly, etc...

        return formattedValue;
    }

    static getDerivedFormat(formats, value, requestedFormat) {
        let formatName = requestedFormat;

        // If 'any' format is passed in, select a reasonable format
        if (requestedFormat === 'any') {
            if (isFinite(value)) {
                formatName = 'number';
            } else if (isDate(value)) {
                formatName = 'date';
            }
        }

        // If we have a supported format, make ensure it has a dataType
        const format = formats[formatName];
        if (format) {
            if (!format.dataType) {
                throw new Error('Format missing dataType.', formatName);
            }
            formatName = format.dataType;
        }

        return formatName;
    }

    number(value, format) {
        return this.format(value, format || 'number');
    }
    date(value, format) {
        return this.format(value, format || 'date');
    }
    currency(value, code) {
        const opts = {
            style: 'currency',
            currency: code || this.currencyCode,
        };
        return this.format(value, 'number', opts);
    }
}
